import react, {useState} from 'react'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import {useTranslation} from "react-i18next";

export default function TerminModal({ open, onCloseModal }) {

    // const [open, setOpen] = useState(true);
    //
    // const onOpenModal = () => setOpen(true);
    // const onCloseModal = () => setOpen(false);

    const { t } = useTranslation();

    const doctorLinks = [
        {
            id: 1,
            name: 'Dr. Mausam Dahiya',
            link: 'https://www.doctolib.de/internist/grevenbroich/mausam-dahiya/booking/motives?specialityId=1302&telehealth=false&placeId=practice-195259&bookingFunnelSource=profile',
        },
        {
            id: 2,
            name: 'Dr. Anil Bharwani',
            link: 'https://www.doctolib.de/internist/grevenbroich/anilkumar-bharwani-grevenbroich/booking/motives?specialityId=1302&telehealth=false&placeId=practice-195259&pid=practice-195259&bookingFunnelSource=profile',
        },
    ]

    return (
        <div>
            <Modal open={open} onClose={onCloseModal} center  focusTrapped={false} closeIcon={<i></i>}>
                <h2 style={{ fontWeight: 'bold' }}>{t('termin_modal_title')}</h2>
                <p>{t('termin_modal_subtitle')}</p>
                <ul style={{ width: '100%', alignItems: 'center'}}>
                    {doctorLinks.map((doctor) => (
                        <li key={doctor.id}>
                            <button
                                onClick={() => window.open(doctor.link, '_blank')}
                                className={'termin-button'}
                                style={{
                                    padding: 10,
                                    borderRadius: 10,
                                    backgroundColor: '#107aca',
                                    color: 'white',
                                    border: 0,
                                    margin: 5
                                }}
                            >
                                {doctor.name}
                            </button>
                        </li>
                    ))}
                </ul>
            </Modal>
        </div>
    )
}


