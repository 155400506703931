import React from 'react';
import doctolibImg from '../../images/icon/doctolib.svg'
import './style.css'

const Scrollbar = ({openTerminModal}) => {

    const goToTermin = () => {
        const terminLink = 'https://www.doctolib.de/internist/grevenbroich/mausam-dahiya/booking/motives?specialityId=1302&telehealth=false&placeId=practice-195259&bookingFunnelSource=profile'
        window.open(terminLink, '_blank')
    }

    return(
        <div className="col-lg-12">
            <div className="header-menu">
                <ul className="smothscroll">
                    <li className="termin-button" onClick={openTerminModal}>
                        <img src={doctolibImg} style={{ height: 60, width: 90 }}/>
                    </li>
                </ul>
            </div>
        </div>
        
    )
}

export default Scrollbar;
