import React from 'react';
import ContactForm from '../ContactFrom/ContactForm'
import {useTranslation} from "react-i18next";


const Contactpage = () => {

    const { t } = useTranslation();

    const redirectToMaps = () => {
        const link = 'https://maps.app.goo.gl/L5VEEe3DXqVW4JQc6'
        window.open(link, '_blank');
    }

    const openMail = () => {
        const link = 'mailto:praxisfrimmersdorf@gmail.com'
        window.open(link, '_blank');
    }

    const openPhoneNumber = () => {
        const link = 'tel:0218180139'
        window.open(link, '_blank');
    }

    return(
        <section id='bestellung' className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-1">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon" onClick={redirectToMaps} style={{ cursor: 'pointer'}}>
                                                <i className="fi flaticon-placeholder"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2 style={{ fontWeight: 'bold'}}>{t('contact_address')}</h2>
                                            <p>Auf dem Leuchtenberg 48, 41517 Grevenbroich</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon" onClick={openMail} style={{ cursor: 'pointer'}}>
                                                <i className="fi flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2 style={{ fontWeight: 'bold'}}>{t('contact_email')}</h2>
                                            <p>praxisfrimmersdorf@gmail.com</p>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon" onClick={openPhoneNumber} style={{ cursor: 'pointer'}}>
                                                <i className="fi flaticon-phone-call"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2 style={{ fontWeight: 'bold'}}>{t('contact_phone')}</h2>
                                            <p>0 21 81 / 80 139</p>
                                            <p>0 21 81 / 16 49 812</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="wpo-contact-title">
                            <h2 style={{ fontWeight: 'bold'}}>{t('hero_feat_3')}</h2>
                            {/*<p>{t('contact_text_1')}<br /> <b>{t('contact_text_2')}</b></p>*/}
                            <p>{t('contact_form_coming_soon')}</p>
                        </div>
                        {/*<div className="wpo-contact-form-area">*/}
                        {/*    /!*<ContactForm/>*!/*/}
                        {/*    Coming Soon...*/}
                        {/*</div>*/}
                    </div>                
                </div>
            </div>
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                    <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.4074770882735!2d6.575884776769477!3d51.04556347171187!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf4f5a171b2b43%3A0xd95cf22f40e57a2e!2sHAUS%C3%84RZTE%20FRIMMERSDORF!5e0!3m2!1sen!2sde!4v1710357437110!5m2!1sen!2sde"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
