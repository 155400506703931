import React, {Fragment, useState} from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero from '../../components/hero/Hero';
import MarqueeSection from '../../components/MarqueeSection/MarqueeSection';
import ServiceSection from '../../components/ServiceSection/ServiceSection';
import WhyChoose from '../../components/WhyChoose/WhyChoose';
import TeamSection from '../../components/TeamSection/TeamSection';
import Testimonial from '../../components/Testimonial/Testimonial';
import BlogSection from '../../components/BlogSection/BlogSection';
import AppoinmentSec from '../../components/AppoinmentSec/AppoinmentSec';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar'
import Contactpage from "../../components/Contactpage/Contactpage";
import TerminModal from "../../components/TerminModal/TerminModal";

const HomePage = () => {

    const [terminModalOpen, setTerminModalOpen] = useState(false);

    const openTerminModal = () => {
        setTerminModalOpen(true);
    }

    const closeTerminModal = () => {
        setTerminModalOpen(false);
    }

    return (
        <Fragment>
            <Navbar openTerminModal={openTerminModal}/>
            <Hero openTerminModal={openTerminModal} />
            <MarqueeSection />
            <BlogSection />
            {/*<ServiceSection />*/}
            {/*<WhyChoose />*/}
            {/*<TeamSection />*/}
            <Testimonial />
            <TeamSection />
            {/*<About />*/}
            <Contactpage/>
            <Footer />
            <Scrollbar openTerminModal={openTerminModal} />
            <TerminModal open={terminModalOpen} onCloseModal={closeTerminModal} />
        </Fragment>
    )
};

export default HomePage;