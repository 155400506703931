import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import Logo from '../../images/logo.svg'
import { Us, De } from "react-flags-select";

import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);
    const [selectedLan, setSelectedLan] = useState('de');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(selectedLan)
    }, [])

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const onLanSelect = (lan) => {
        setSelectedLan(lan)
        i18n.changeLanguage(lan)
    }

    const jumpToHash = (id) => {
        window.location.href = '#' + id;
    }

    const goToTermin = () => {
        const terminLink = 'https://www.doctolib.de/internist/grevenbroich/anilkumar-bharwani-grevenbroich/booking/motives?specialityId=1302&telehealth=false&placeId=practice-195259&pid=practice-195259&bookingFunnelSource=profile'
        window.open(terminLink, '_blank')
    }

    return (

        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/"><img src={Logo} alt="" /></Link>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li className="menu-item-has-children">
                                            <Link onClick={() => jumpToHash('angebot')} to="#angebot">{t('Our Services')}</Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={() => jumpToHash('praxis')} to="#praxis">{t('Praxis')}</Link>
                                        </li>
                                        <li className="menu-item-has-children">
                                            <Link onClick={() => jumpToHash('team')} to="#team">{t('Team')}</Link>
                                        </li>
                                        {/*<li className="menu-item-has-children">*/}
                                        {/*    <Link onClick={() => jumpToHash('about')} to="#about">{t('About Us')}</Link>*/}
                                        {/*</li>*/}
                                        <li><Link onClick={() => jumpToHash('bestellung')} to="#bestellung">{t('Online Rezeptbestellung')}</Link></li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                {selectedLan === 'en' ? <Us/> : <De/>}
                                            </Link>
                                            <ul className="sub-menu">
                                                <li><Link onClick={() => onLanSelect('de')}
                                                          to="/">
                                                    <De/> German</Link>
                                                </li>
                                                <li><Link onClick={() => onLanSelect('en')}
                                                          to="/">
                                                    <Us/> English</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-2 col-2">
                                <div className="header-right">
                                    <div className="close-form">
                                        <Link onClick={props.openTerminModal} className="theme-btn" to="/">{t('Book Now')}</Link>
                                    </div>
                                    <div className="header-search-form-wrapper">
                                        <div className="cart-search-contact">
                                            <button onClick={() => setMenuState(!menuActive)}
                                                    className="search-toggle-btn"><i
                                                className={`fi ti-search ${menuActive ? "ti-close" : "ti-search"}`}></i></button>
                                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search here..." />
                                                        <button type="submit"><i
                                                            className="fi flaticon-loupe"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;