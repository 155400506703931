import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'

import {useTranslation} from "react-i18next";

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const SubmitHandler = (e) => {
    e.preventDefault()
}


const Footer = (props) => {

    const { t } = useTranslation()

    const isClinicOpen = () => {
        const date = new Date();
        const currentDay = date.getDay();
        const currentHour = date.getHours();

        // Clinic is closed on Saturday and Sunday
        if (currentDay === 0 || currentDay === 6) {
            return false;
        }

        const openingHours = {
            1: [{ start: 8, end: 13.5 }, { start: 16, end: 18 }], // Monday
            2: [{ start: 8, end: 13.5 }], // Tuesday
            3: [{ start: 8, end: 12 }], // Wednesday
            4: [{ start: 8, end: 13.5 }, { start: 16, end: 18 }], // Thursday
            5: [{ start: 8, end: 12 }] // Friday
        };

        // Check if current time is within any of the opening hours intervals
        return openingHours[currentDay].some(interval => currentHour >= interval.start && currentHour < interval.end);
    }

    const navigateToLinkedIn = () => {
        const link = 'https://www.linkedin.com/company/haus%C3%A4rzte-frimmersdorf'
        window.open(link, '_blank')
    }

    return (
        <footer className={`wpo-site-footer ${props.footerClass}`}>
            <div className="f-shape-1">
                <svg width="887" height="757" viewBox="0 0 887 757" fill="none">
                    <g opacity="0.6" filter="url(#filter0_f_235_142)">
                        <circle cx="353" cy="223" r="234" />
                    </g>
                    <defs>
                        <filter id="filter0_f_235_142" x="-181" y="-311" width="1068" height="1068"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_235_142" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div className="f-shape-2">
                <svg width="696" height="606" viewBox="0 0 696 606" fill="none">
                    <g opacity="0.6" filter="url(#filter0_f_235_143)">
                        <circle cx="534" cy="534" r="234" />
                    </g>
                    <defs>
                        <filter id="filter0_f_235_143" x="0" y="0" width="1068" height="1068"
                            filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                            <feGaussianBlur stdDeviation="150" result="effect1_foregroundBlur_235_143" />
                        </filter>
                    </defs>
                </svg>
            </div>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <img src={Logo} alt="blog" width={300}/>
                                </div>
                                <p>{t('footer_text')}</p>
                                <div className="social-widget">
                                    <ul>
                                        <li>
                                            <Link onClick={navigateToLinkedIn} to="/">
                                                <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-contact-widget">
                                <div className="widget-title">
                                    <h3 style={{fontWeight: 'bold'}}>{t('footer_time_title')}</h3>
                                    <p
                                        style={{
                                            position: 'relative',
                                            fontWeight: 'bold',
                                            margin: 0,
                                            color: isClinicOpen() ? 'green' : 'red' }}
                                    >
                                        {isClinicOpen() ? t('footer_time_open'): t('footer_time_close')}
                                    </p>
                                </div>
                                <div className="contact-ft">
                                    <table style={{ width: '100%' }}>
                                        <tbody>
                                            <tr style={{
                                                fontWeight: new Date().getDay() === 1 ? 'bold' : '300',
                                                width: '100%'
                                            }}>
                                                <td>{t('footer_time_monday')}</td>
                                                <td>08:00 - 13:30</td>
                                                <td>16:00 - 18:00</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 2 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_tuesday')}</td>
                                                <td>08:00 - 13:30</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 3 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_wednesday')}</td>
                                                <td>08:00 - 12:00</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 4 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_thursday')}</td>
                                                <td>08:00 - 13:30</td>
                                                <td>16:00 - 18:00</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 5 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_friday')}</td>
                                                <td>08:00 - 12:00</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 6 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_saturday')}</td>
                                                <td>{t('footer_time_closed')}</td>
                                            </tr>
                                            <tr style={{fontWeight: new Date().getDay() === 0 ? 'bold' : '300'}}>
                                                <td>{t('footer_time_sunday')}</td>
                                                <td>{t('footer_time_closed')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-contact-widget">
                                <div className="widget-title">
                                    <h3 style={{fontWeight: 'bold'}}>{t('footer_contact_title')}</h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-placeholder"></i>Auf dem Leuchtenberg 48, 41517
                                            Grevenbroich
                                        </li>
                                        <li><i className="fi flaticon-phone-call"></i>0 21 81 / 80 139</li>
                                        <li><i className="fi flaticon-diary"></i>0 21 81 / 16 49 812</li>
                                        <li><i className="fi flaticon-email"></i>praxisfrimmersdorf@gmail.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright">Copyright &copy;<span>2024</span>
                                <span className="copyright-icon">|</span> All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;