import React, {Fragment, useEffect, useState} from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import { Link }  from "react-router-dom";
import './style.css';
import {De, Us} from "react-flags-select";
import {useTranslation} from "react-i18next";

const menus = [
    {
        id: 5,
        submenu: [
            {
                id: 11,
                language: 'de',
                flag: <De />
            },
            {
                id: 12,
                language: 'en',
                flag: <Us />
            }
        ]
    }
]


const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);
    const [selectedLan, setSelectedLan] = useState('de');

    const { t, i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(selectedLan)
    }, [])

    const onLanSelect = (lan) => {
        setSelectedLan(lan)
        i18n.changeLanguage(lan)
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const jumpToHash = (id) => {
        window.location.href = '#' + id;
    }

    return (
        <div>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    <ListItem className={openId === 0 ? 'active' : null}>
                        <Link
                            onClick={() => jumpToHash('angebot')}
                            to="#angebot"
                        >
                            {t('Our Services')}
                        </Link>
                    </ListItem>
                    <ListItem className={openId === 1 ? 'active' : null}>
                        <Link
                            onClick={() => jumpToHash('praxis')}
                            to="#praxis"
                        >
                            {t('Praxis')}
                        </Link>
                    </ListItem>
                    <ListItem className={openId === 2 ? 'active' : null}>
                        <Link
                            onClick={() => jumpToHash('team')}
                            to="#team"
                        >
                            {t('Team')}
                        </Link>
                    </ListItem>
                    {/*<ListItem className={openId === 3 ? 'active' : null}>*/}
                    {/*    <Link*/}
                    {/*        onClick={() => jumpToHash('about')}*/}
                    {/*        to="#about"*/}
                    {/*    >*/}
                    {/*        {t('About Us')}*/}
                    {/*    </Link>*/}
                    {/*</ListItem>*/}
                    <ListItem className={openId === 4 ? 'active' : null}>
                        <Link
                            onClick={() => jumpToHash('bestellung')}
                            to="#bestellung"
                        >
                            {t('Online Rezeptbestellung')}
                        </Link>
                    </ListItem>
                    {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null}  key={mn}>
                                <Fragment>
                                    <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{selectedLan === 'en' ? <Us/> : <De/>}
                                        <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                    </p>
                                    <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                        <List className="subMenu">
                                            <Fragment>
                                                {item.submenu.map((submenu, i) => {
                                                    return (
                                                        <ListItem key={i}>
                                                            <Link onClick={() => onLanSelect(submenu.language)}
                                                                  to='/'>{submenu.flag}</Link>
                                                        </ListItem>
                                                    )
                                                })}
                                            </Fragment>
                                        </List>
                                    </Collapse>
                                </Fragment>
                            </ListItem>
                        )
                    })}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;