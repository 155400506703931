import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const localPath = `http://localhost:3036/locales/{{lng}}.json`;
const prodPath = `https://hausarzt-frimmersdorf.vercel.app/locales/{{lng}}.json`;

i18n
    .use(i18nBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        lng: 'en',
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: process.env.NODE_ENV === 'production' ? prodPath : localPath,
        }
    });

export default i18n;