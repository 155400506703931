import React from "react";
import {useTranslation} from "react-i18next";

const MarqueeSection = () => {

    const { t } = useTranslation();

    return (
        <div className="digital-marque-sec">
            <div className="digital-marque">
                <div className="marquee">
                    <div className="track">
                        <div className="content">
                            <h1>
                                <span>{t('marquee_1')}<i className="ti-star"></i></span>
                                <span>{t('marquee_2')}<i className="ti-star"></i></span>
                                <span>{t('marquee_3')}<i className="ti-star"></i></span>
                                <span>{t('marquee_4')}<i className="ti-star"></i></span>
                                <span>{t('marquee_5')}<i className="ti-star"></i></span>
                                <span>{t('marquee_1')}<i className="ti-star"></i></span>
                                <span>{t('marquee_2')}<i className="ti-star"></i></span>
                                <span>{t('marquee_3')}<i className="ti-star"></i></span>
                                <span>{t('marquee_4')}<i className="ti-star"></i></span>
                                <span>{t('marquee_5')}<i className="ti-star"></i></span>
                                <span>{t('marquee_1')}<i className="ti-star"></i></span>
                                <span>{t('marquee_2')}<i className="ti-star"></i></span>
                                <span>{t('marquee_3')}<i className="ti-star"></i></span>
                                <span>{t('marquee_4')}<i className="ti-star"></i></span>
                                <span>{t('marquee_5')}<i className="ti-star"></i></span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarqueeSection;