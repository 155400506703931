import React from 'react'
import { Link } from 'react-router-dom'
import { Slide } from "react-awesome-reveal";
import mausam from "../../images/team/mausam.png";
import anil from "../../images/team/anil.png";
import karahan from "../../images/team/karahan.png";
import anne from "../../images/team/anne.png";
import rasol from "../../images/team/rasol.png";
import {useTranslation} from "react-i18next";

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const team = [
    {
        id: '1',
        tImg: mausam,
        name: 'Dr. Mausam Dahiya',
        title: 'team_dr',
        animation:'1000',
    },
    {
        id: '2',
        tImg: anil,
        name: 'Dr. Anil Bharwani',
        title: 'team_dr',
        animation:'1200',
    },
    {
        id: '3',
        tImg: karahan,
        name: 'Frau Ayse Karahan',
        title: 'team_MFA',
        animation:'1400',
    },
    {
        id: '4',
        tImg: anne,
        name: 'Frau Anne Esser',
        title: 'team_MFA',
        animation:'1600',
    },
    {
        id: '5',
        tImg: rasol,
        name: 'Frau Rosol Alshalash',
        title: 'team_PA',
        animation:'1800',
    },
]

const TeamSection = (props) => {

    const { t } = useTranslation();

    return (
        <section id='team' className={`wpo-team-section section-padding ${props.tClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            <span>{t('team_subtitle')}</span>
                            <h2 style={{ fontWeight: 'bold' }}>{t('team_title')}</h2>
                        </div>
                    </div>
                </div>
                <div className="team-wrap">
                    <div className="row">
                        {team.map((team, tm) => (
                            <div className="col-lg-4 col-md-6 col-12" key={tm}>
                                <Slide direction="up" duration={team.animation} triggerOnce="true">
                                    <div className="team-single">
                                        <div className="team-boder-shapes-1">
                                            <div className="team-single-img">
                                                <img src={team.tImg} alt="" />
                                            </div>
                                            <div className="team-single-text">
                                                <h2 style={{ fontWeight: 'bold' }}>{team.name}</h2>
                                                <span>{t(team.title)}</span>
                                            </div>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection;