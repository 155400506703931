import React from "react";
import blogs from '../../api/blogs'
import blogImg1 from "../../images/blog/img-1.jpg";
import primeCare from "../../images/services/primary_care.png";
import img2 from '../../images/services/img2.png'
import img3 from '../../images/services/img3.png'
import img4 from '../../images/services/img4.png'
import img5 from '../../images/services/img5.png'
import img6 from '../../images/services/img6.png'
import img7 from '../../images/services/img7.png'
import img8 from '../../images/services/img8.png'
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

import {useTranslation} from "react-i18next";


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const BlogSection = (props) => {

    const { t } = useTranslation();

    return (
        <section id='angebot' className={`wpo-blog-section section-padding ${props.blClass}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="wpo-section-title">
                            <span>{t('services_subtitle')}</span>
                            <h2 style={{ fontWeight: 'bold' }}>{t('services_title')}</h2>
                        </div>
                    </div>
                </div>
                <div className="blog-wrap">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1200} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={primeCare} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_1_title')}</h3>
                                        <p>{t('service_1_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1400} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img2} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_2_title')}</h3>
                                        <p>{t('service_2_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1600} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img3} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_3_title')}</h3>
                                        <p>{t('service_3_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 10}}>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1200} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img4} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_4_title')}</h3>
                                        <p>{t('service_4_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1400} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img5} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_5_title')}</h3>
                                        <p>{t('service_5_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1600} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img6} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_6_title')}</h3>
                                        <p>{t('service_6_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <div className="row" style={{marginTop: 10}}>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={1800} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img7} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_7_title')}</h3>
                                        <p>{t('service_7_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            <Slide direction="up" duration={2000} triggerOnce="true">
                                <div className="blog-single">
                                    {/*<a href="blog-single.html" className="blog-btn">Medicine</a>*/}
                                    <div className="blog-single-img">
                                        <img src={img8} alt=""/>
                                    </div>
                                    <div className="blog-single-text">
                                        <h3 style={{fontWeight: 'bold'}}>{t('service_8_title')}</h3>
                                        <p>{t('service_8_text')}</p>
                                        {/*<span>{blog.create_at}</span>*/}
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        {/*<div className="col-lg-4 col-md-6 col-12">*/}
                        {/*    <Slide direction="up" duration={1600} triggerOnce="true">*/}
                        {/*        <div className="blog-single">*/}
                        {/*            /!*<a href="blog-single.html" className="blog-btn">Medicine</a>*!/*/}
                        {/*            <div className="blog-single-img">*/}
                        {/*                <img src={img6} alt=""/>*/}
                        {/*            </div>*/}
                        {/*            <div className="blog-single-text">*/}
                        {/*                <h3 style={{fontWeight: 'bold'}}>{t('service_6_title')}</h3>*/}
                        {/*                <p>{t('service_6_text')}</p>*/}
                        {/*                /!*<span>{blog.create_at}</span>*!/*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </Slide>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogSection;