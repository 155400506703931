import React, { useState } from 'react';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import sliderImg1 from '../../images/slider/slider_img_1.png'
import sliderImg2 from '../../images/slider/slider_img_2.png'
import sliderImg3 from '../../images/slider/slider_img_3.png'
import sliderImg4 from '../../images/slider/slider_img_4.png'
import sliderImg5 from '../../images/slider/slider_img_5.png'
import shape from '../../images/testimonial-shape.png'
import SimpleImageSlider from "react-simple-image-slider";
import { useMediaQuery } from 'react-responsive'
import {useTranslation} from "react-i18next";

const praxis = [
    { url: sliderImg1 },
    { url: sliderImg2 },
    { url: sliderImg3 },
    { url: sliderImg4 },
    { url: sliderImg5 },
]

const Testimonial = () => {

    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })

    const { t } = useTranslation();


    return (
        <section id='praxis' className="wpo-testimonial-section section-padding">
            <div className="testimonial-shape">
                <img src={shape} alt=""/>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10">
                    <div className="wpo-section-title">
                        <span>{t('praxis_subtitle')}</span>
                        <h2 style={{ fontWeight: 'bold' }}>{t('praxis_title')}</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <SimpleImageSlider
                    width={isDesktopOrLaptop ? 960 : 300}
                    style={{margin: 'auto'}}
                    height={isDesktopOrLaptop ? 640 : 200}
                    images={praxis}
                    showBullets={false}
                    showNavs={isDesktopOrLaptop}
                    // navSize={isDesktopOrLaptop ? 50 : 20}
                    autoPlay={true}
                />
            </div>
        </section>
    );
}

export default Testimonial;